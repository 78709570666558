import {history} from "../../../../../history";
import {IUser, UserRoles} from "../../../../../store/cms/usersNRoles/types";

export const isAdmin = (user: IUser): boolean => {
    if (Array.isArray(user?.roles)) {
        return user?.roles.includes(UserRoles.Admin);
    }
    return false;
}

export const isClientAdmin = (user: IUser): boolean => {
    if (Array.isArray(user?.roles)) {
        return user?.roles.includes(UserRoles.ClientAdmin);
    }
    return false;
}

const logout = () => {
    localStorage.clear();
    history.push('/login');
}

export const renderSidebarItems = (user: IUser, url: string) => {
    if (isAdmin(user) || isClientAdmin(user)) {
        return [
            {
                id: 'darts',
                name: 'Darts',
                iconName: 'adjust',
                children: [
                    {
                        id: 'darts-games',
                        name: 'Games',
                        onClick: () => history.push(`${url}/darts/games`)
                    },
                    {
                        id: 'darts-shootout-games',
                        name: 'Shootout Games',
                        onClick: () => history.push(`${url}/darts/shootout`)
                    },
                    {
                        id: 'darts-adc-games',
                        name: 'ADC Qualifier Games',
                        onClick: () => history.push(`${url}/darts/adc-games`)
                    },
                    {
                        id: 'darts-players',
                        name: 'Players',
                        onClick: () => history.push(`${url}/darts/players`)
                    },
                    {
                        id: 'darts-competitions',
                        name: 'Competitions',
                        onClick: () => history.push(`${url}/darts/competitions`)
                    },

                    {
                        id: 'darts-phases',
                        name: 'Phases',
                        onClick: () => history.push(`${url}/darts/phase`)
                    },
                    {
                        id: 'darts-weeks',
                        name: 'Weeks',
                        onClick: () => history.push(`${url}/darts/weeks`)
                    },
                    {
                        id: 'darts-groups',
                        name: 'Groups',
                        onClick: () => history.push(`${url}/darts/groups`)
                    },
                    {
                        id: 'darts-tournaments',
                        name: 'Tournaments',
                        onClick: () => history.push(`${url}/darts/tournaments`)
                    },

                ]
            },
            {
                id: 'mss',
                name: 'Modus Super Series',
                onClick: () => history.push(`${url}/mss`),
                iconName: 'edit',
                children: [
                    {
                        id: 'mss-news',
                        name: 'News',
                        onClick: () => history.push(`${url}/mss/news`)
                    },
                ]
            },
            isAdmin(user) ? {
                id: 'clients',
                name: 'Clients',
                onClick: () => history.push(`${url}/clients`),
                iconName: 'user'
            } : null,

            {
                id: 'usersRoles',
                name: 'Users & Roles',
                iconName: 'users',
                children: [
                    {
                        id: 'users',
                        name: 'Users',
                        onClick: () => history.push(`${url}/users`),
                    },
                    {
                        id: 'listeners',
                        name: 'Listeners',
                        onClick: () => history.push(`${url}/listeners`),
                    },
                    {
                        id: 'roles',
                        name: 'Roles',
                        onClick: () => history.push(`${url}/roles`),
                    }
                ]
            },
            {
                id: 'schedule',
                name: 'Schedule',
                onClick: () => history.push('/schedule'),
                iconName: 'game'
            },
            {
                id: 'logout',
                name: 'Logout',
                onClick: () => logout(),
                iconName: 'x'
            },
        ].filter((item) => item !== null);
    }
    return [
        {
            id: 'darts',
            name: 'Darts',
            iconName: 'adjust',
            children: [
                {
                    id: 'darts-games',
                    name: 'Games',
                    onClick: () => history.push(`${url}/darts/games`)
                },
                {
                    id: 'darts-shootout-games',
                    name: 'Shootout Games',
                    onClick: () => history.push(`${url}/darts/shootout`)
                },
                {
                    id: 'darts-adc-games',
                    name: 'ADC Qualifier Games',
                    onClick: () => history.push(`${url}/darts/adc-games`)
                },
                {
                    id: 'darts-players',
                    name: 'Players',
                    onClick: () => history.push(`${url}/darts/players`)
                },
                {
                    id: 'darts-competitions',
                    name: 'Competitions',
                    onClick: () => history.push(`${url}/darts/competitions`)
                },

                {
                    id: 'darts-phases',
                    name: 'Phases',
                    onClick: () => history.push(`${url}/darts/phase`)
                },
                {
                    id: 'darts-weeks',
                    name: 'Weeks',
                    onClick: () => history.push(`${url}/darts/weeks`)
                },
                {
                    id: 'darts-groups',
                    name: 'Groups',
                    onClick: () => history.push(`${url}/darts/groups`)
                },
                {
                    id: 'darts-tournaments',
                    name: 'Tournaments',
                    onClick: () => history.push(`${url}/darts/tournaments`)
                },

            ]
        },
        {
            id: 'mss',
            name: 'Modus Super Series',
            onClick: () => history.push(`${url}/mss`),
            iconName: 'edit',
            children: [
                {
                    id: 'mss-news',
                    name: 'News',
                    onClick: () => history.push(`${url}/mss/news`)
                },
            ]
        },
        {
            id: 'clients',
            name: 'Clients',
            onClick: () => history.push(`${url}/clients`),
            iconName: 'user'
        },

        {
            id: 'schedule',
            name: 'Schedule',
            onClick: () => history.push('/schedule'),
            iconName: 'game'
        },
        {
            id: 'logout',
            name: 'Logout',
            onClick: () => logout(),
            iconName: 'x'
        },
    ]
}
